import { ROLE } from '../../../common/role';
import {
  formatTreatmentPlanName,
  formatTreatmentPlanNameToWasm,
  isDrEditedPlan,
  isNotIFSPlan,
  getSetupNumber
} from '../wasm_helper';
import { WASM_PROP } from '../wasm_controller/wasm_controller_const';
import { CaseStatus } from '../../../common/case/case_details.constants';
import { combineFilters } from '../../../common/utils/filters';
import { getBusinessRoleList } from '../../../common/helpers';
import { isIFSReadyForRelease } from '../../../common/case/case_status';
import { getTreatmentPlanList } from '../wasm_controller/wasm_controller';
import { ECasePrimeStatus } from '../../../common/case/case-prime-status';
import { buildHistoryListFromCaseDetail } from './rev-history';

function getMaxSetupNum(caseDetails, caseFileList) {
  const caseFile = caseFileList[caseFileList.case_id];
  if (!caseFile.setup_process) {
    return 0;
  }
  const isAbleToSeeUncompleted = caseDetails.role !== ROLE.Doctor;
  const setupProcess = isAbleToSeeUncompleted ? caseFile.setup_process : caseFile.setup_process.filter((s) => s.process_completed);
  const latestCompletedSetup = setupProcess.at(-1);
  return latestCompletedSetup.index;
}

function isValidTxPlanName(name, maxSetupNumber) {
  const re1 = /^smiledesign([0-9]+)(-ifs)?$/;
  const re2 = /^smiledesign([0-9]+)(-dr\.edit)(-ifs)?$/;
  const isValidName = name.match(re1);
  const isValidDrPlan = name.match(re2);
  const setupNumber = getSetupNumber(name);

  const isValidFormat = isValidName || isValidDrPlan;
  const isValidSetupNumber = setupNumber <= maxSetupNumber;

  return Boolean(isValidFormat && isValidSetupNumber);
}

function isValidSubCaseTxPlanName(name, maxSetupNumber) {
  const re1 = /^smiledesign([0-9]+)-de([0-9]+)(-ifs)?$/;
  const re2 = /^smiledesign([0-9]+)(-dr\.edit)(-ifs)?$/;
  const isValidName = name.match(re1);
  const isValidDrPlan = name.match(re2);
  const setup_number = getSetupNumber(name);

  const isValidFormat = isValidName || isValidDrPlan;
  const isValidSetupNumber = setup_number <= maxSetupNumber;

  return Boolean(isValidFormat && isValidSetupNumber);
}

function getFiltersByPrimeStatus(primeStatus, caseDetails, caseFileList) {
  const takeValidTreatmentPlan = (tr) => isValidTxPlanName(tr.rawName, tr.case.maxSetupNumber);
  const takeOnlyLockedPlansForViewing = (tr) => tr.case.isRevising || tr.isLocked;
  const takeDrEditPlansBeforeRevision = (tr) => {
    const isAwaitingDoctorDecision = tr.case.caseStatus?.case_status === CaseStatus.DoctorApproveOrReviseSetup;
    return isAwaitingDoctorDecision && !tr.case.isRevising ? !tr.rawName.includes(`smiledesign${tr.case.maxSetupNumber}-dr.edit`) : true;
  };

  const takeCorrectTreatmentPlans = combineFilters(takeValidTreatmentPlan, takeOnlyLockedPlansForViewing, takeDrEditPlansBeforeRevision);
  const takeNotIFSPlans = combineFilters(takeCorrectTreatmentPlans, isNotIFSPlan);

  const caseFile = caseFileList[caseFileList.case_id];
  const historyList = buildHistoryListFromCaseDetail(caseFile.setup_process, caseDetails.role);
  const lastHistoryItemName = formatTreatmentPlanNameToWasm(historyList.at(0)?.title || '');
  const isDrEdited = isDrEditedPlan(lastHistoryItemName);
  const takeIFSPlan = (tr) => (isDrEdited
    ? tr.rawName === `smiledesign${tr.case.maxSetupNumber}-dr.edit-ifs`
    : tr.rawName === `smiledesign${tr.case.maxSetupNumber}-ifs`);
  const isDoctor = caseDetails.role === ROLE.Doctor;
  const isDSO = !getBusinessRoleList().includes(caseDetails.role);
  const currentCase = caseDetails.casesMap?.[caseDetails.case_id];
  const isIfsReadyForRelease = isIFSReadyForRelease(currentCase);
  const isIFSVisible = !isDSO && !isDoctor && isIfsReadyForRelease;

  // Sub Case rules
  // 1. replace smiledesign<N>-de<M> => smiledesign-de<M>
  // Correct tx plan names for DEs:
  // 1. smiledesign-de<M>               => Smile Design <N> DE<M>
  // 2. smiledesign-de<M>-dr.edit       => Smile Design <N> DE<M> - Revision
  // 3. smiledesign-de<M>-ifs           => Smile Design <N> DE<M> (IFS)
  // 4. smiledesign-de<M>-dr.edit-ifs   => Smile Design <N> DE<M> - Revision (IFS)

  switch (primeStatus) {
    case ECasePrimeStatus.Sub:
    case ECasePrimeStatus.Prime:
    case ECasePrimeStatus.Classic:
      return {
        takeTxPlan: takeNotIFSPlans,
        takeIfsPlan: isIFSVisible ? takeIFSPlan : () => false,
      };
  }
}

function fmtSubCaseRawTxPlanName(txPlanName) {
  return txPlanName.split('-').map(s => s === 'de' ? 'de1' : s).join('-');
}

export function getTxPlans(caseDetails, caseFileList, isRevising) {
  // fetch tx plans
  const rawTreatmentPlanList = getTreatmentPlanList();
  const maxSetupNumber = getMaxSetupNum(caseDetails, caseFileList);
  const currentCase = caseDetails.casesMap[caseDetails.case_id];
  const caseStatus = currentCase.case_statuses.at(-1);
  const isSubCase = !!currentCase.prime_id;

  // transform tx plans
  const mapTreatmentPlan = (tr, index) => ({
    name: formatTreatmentPlanName(tr[WASM_PROP.CaseInfo.Name]),
    rawName: tr[WASM_PROP.CaseInfo.Name],
    index,
    isLocked: tr[WASM_PROP.CaseInfo.IsLocked],
    case: {
      maxSetupNumber,
      isRevising,
      caseStatus
    }
  });
  let mappedTxPlans = rawTreatmentPlanList.map(mapTreatmentPlan);
  // map DE tx plans names as if it would be a classic treatment plan name so far
  // TODO: when upper management approves this, show all Initial and DE tx plans within a PrimeCase concept
  if (isSubCase) {
    const mapSubCaseTxPlan = (tr) => {
      const rawName = tr.rawName.replace(/^smiledesign[0-9]*-de[0-9]*-sd/, 'smiledesign');
      const name = formatTreatmentPlanName(rawName);
      return {
        ...tr,
        rawName,
        name,
        initialName: tr.rawName,
      };
    };
    let deNum = currentCase.case_id.toLowerCase().split('-de').at(1) || '';
    deNum = deNum === '1' ? '' : deNum;
    mappedTxPlans = mappedTxPlans.filter(tr => tr.rawName.includes(`-de${deNum}-`)).map(mapSubCaseTxPlan);
  }

  // filter tx plans
  const { takeTxPlan, takeIfsPlan } = getFiltersByPrimeStatus(currentCase?.prime_status, caseDetails, caseFileList);
  const txPlans = mappedTxPlans.filter(takeTxPlan);
  const ifsPlans = mappedTxPlans.filter(takeIfsPlan);

  // return tx plans
  return [...txPlans, ...ifsPlans];
}
