import { createSelector } from 'reselect';

import { FETCH_CASE_DETAILS_START, FETCH_CASE_DETAILS_SUCCESS, FETCH_CASE_DETAILS_ERROR, CLEAR_DRAFT_SAVES } from '../../actions/common/common_case_details';
import { CaseStatus, ECaseProcessStatus } from '../../../common/case/case_details.constants';
import { PROGRAMS_FRONTEND_CONSTANTS } from '../../../common/constants/programs';
import { ECasePrimeStatus } from '../../../common/case/case-prime-status';
import { ETreatmentPlanStage } from '../../../components/wasm_viewer/wasm-constants';

const initialState = {
  case_details_loading: false,
  case_details_error: null,
  case_details: null,
};

export function commonCaseDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CASE_DETAILS_START:
      return { ...state, case_details_loading: true };

    case FETCH_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        case_details: action.case_details,
        case_details_loading: false,
      };

    case FETCH_CASE_DETAILS_ERROR:
      return {
        ...state,
        case_details_error: action.error,
        case_details_loading: false,
      };

    case CLEAR_DRAFT_SAVES:
      return clearDraftSaves(state, action);

    default:
      return state;
  }
}

// FIXME: remove this dirty hack when API and architecture gets normal, and when request of case_details will take smaller time
/**
 * Nullifies process_status_code and process_status_text of the latest case status
 * @param state
 * @param action
 * @return {*&{case_details: (*&{cases: *[]})}}
 */
function clearDraftSaves(state, action) {
  const { caseId } = action.payload;
  const cases = [...(state.case_details?.cases || [])];
  const currentCaseIndex = cases.findIndex((c) => c.case_id === caseId);
  if (currentCaseIndex >= 0) {
    const currentCase = { ...cases[currentCaseIndex] };
    const statuses = [...currentCase.case_statuses];
    const currentStatus = {
      ...statuses.at(-1),
      process_status_code: null,
      process_status_text: null,
    };
    statuses.splice(-1, 1, currentStatus);
    currentCase.case_statuses = statuses;
    cases.splice(currentCaseIndex, 1, currentCase);
  }
  return { ...state, case_details: { ...state.case_details, cases } };
}

export const getCaseDetails = (state) => state.commonCaseDetailsReducer.case_details;
export const isEnrolledProviderEditPhase2 = createSelector([getCaseDetails], (caseDetails) =>
  caseDetails?.doctor?.program_enrollment?.includes(PROGRAMS_FRONTEND_CONSTANTS.provider_edit_phase_2.program_id)
);
export const getCaseStatus = (state) => {
  const caseDetails = getCaseDetails(state);
  const currentCase = caseDetails?.cases?.find((c) => c.case_id === caseDetails?.case_id);
  return currentCase?.case_statuses?.at(-1);
};
export const getCaseDetailsLoading = (state) => state.commonCaseDetailsReducer.case_details_loading;
export const getCaseDetailsError = (state) => state.commonCaseDetailsReducer.case_details_error;
export const getCasePrimeStatus = createSelector([getCaseDetails], (caseDetails) =>
  caseDetails?.casesMap?.[caseDetails?.case_id]?.prime_status
);
export const getPrimeCaseIdAndEslId = createSelector([getCaseDetails, getCasePrimeStatus], (caseDetails, casePrimeStatus) => {
  let currentCase = caseDetails?.casesMap?.[caseDetails?.case_id];

  if (casePrimeStatus === ECasePrimeStatus.Sub || currentCase?.prime_id) {
    currentCase = caseDetails?.casesMap?.[currentCase?.prime_id];
  }

  return { caseId: currentCase?.case_id, eslingualId: currentCase?.eslingual_id };
});
export const getCaseStages = createSelector([getCasePrimeStatus], (casePrimeStatus) => ({
  malocStage: casePrimeStatus === ECasePrimeStatus.Sub ? ETreatmentPlanStage.DeMal : ETreatmentPlanStage.Malocclusion,
  idealStage: casePrimeStatus === ECasePrimeStatus.Sub ? ETreatmentPlanStage.DeIdeal : ETreatmentPlanStage.Ideal,
  overStage: casePrimeStatus === ECasePrimeStatus.Sub ? ETreatmentPlanStage.DeOver : ETreatmentPlanStage.Overcorrection,
}));

export const getCaseRevisionStatuses = (state) => {
  const caseDetails = state.case_details;
  return (caseDetails?.cases?.find((c) => c.case_id === caseDetails.case_id)?.case_statuses || []).filter(
    (s) => s.case_status === CaseStatus.DoctorApproveOrReviseSetup && s.process_status_code === ECaseProcessStatus.RevisionDraft
  );
};

export const getRevisionLastSavedAt = (state) => {
  const caseStatuses = getCaseRevisionStatuses(state);
  const revisionCreatedAt = caseStatuses.at(-1)?.created_date;
  const revisionModifiedAt = caseStatuses.at(-1)?.modified_date;
  return revisionModifiedAt || revisionCreatedAt;
};

export const getIsRevisionDraftSaved = (state) => {
  const caseStatuses = getCaseRevisionStatuses(state);
  const isRevisionDraftSaved = !!caseStatuses.length;
  return isRevisionDraftSaved;
};
